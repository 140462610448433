import React from "react";
import { useNavigate } from "react-router-dom";
import { Header, Logo } from "@vwfs-bronson/bronson-react";
import { NavBarComponent } from "./NavBar";

export const HeaderComponent = (user,logout) => {
  const navigate = useNavigate();  

  const doLogout = async (e) => {
    user.prop.logout();
    navigate("/");
  }
  return (
    <Header
      staticHeader
      logo={
        <Logo
          ariaLabel="Logo"
          tabIndex={0}
          title="DigitalServices"
          onClick={function () {
            doLogout();
          }}
        />
      }
    >
   {user.prop.user !==null && (<NavBarComponent/>)}
    </Header>
   
  );
};
