import { Layout, Fieldset, Button, Modal } from "@vwfs-bronson/bronson-react";
import React, { useEffect, useState } from "react";
import { ticksToString } from "../utils.js";


function TimeLineGraph({ nameProp, dataProp }) {
  const [name, setName] = useState("");
  const [data, setData] = useState([]);
  const [selectedModal, setSelectedModal] = useState(null);
  const [selectedKO, setSelectedKO] = useState(null);

  const handleOpenModal = (koRecords) => {

    const o = {
      Request: "1",
      Response: "2",
      Origin: "3",
      Destination: "4",
    };

    setSelectedKO(koRecords);

    setSelectedModal(koRecords);
  }
  function handleCloseModal() {
    setSelectedModal(null);
  }

  useEffect(() => {
    console.log("entro y no se donde voy")
    //Ordeno por fecha
    dataProp.sort((a, b) => a.ServiceInsertTime - b.ServiceInsertTime);
    //dataProp.map(
    //    (dataProp) =>
    //        (dataProp.ServiceInsertTime = ticksToString(dataProp.ServiceInsertTime))
    //);

    dataProp.map(
      (dataProp) => {
        if (dataProp.ServiceStatus === "OK") {
          dataProp[200] = dataProp.ResponseTime
        }
        else {
          dataProp[400] = dataProp.ResponseTime
        }
      }
    );
    console.log(dataProp);
 

    setData(dataProp);
    setName(nameProp);


  }, []);

  const groupedByDate = data.reduce((acc, service) => {
    const date = ticksToString(service.ServiceInsertTime).split('T')[0];

    if (!acc[date]) {
      acc[date] = { date, koRecords: [] }
    }
    if (service.ServiceStatus === "OK") {
    }
    else if (service.ServiceStatus === "KO") {
      acc[date].koRecords.push(service);
    } else {//casos que pone server error o cosas asi
      acc[date].koRecords.push(service);
    }
    return acc;
  }, {})

  const resultArray = Object.values(groupedByDate);

  return (
    <Fieldset>
      <Layout center>
        {data.length !== 0 && (
          <Layout.Item default="1/3" s="1/1">
            <p>Servicio {name}</p>
            <div className="status-container">
              {resultArray.slice(0, 60).map((day, index) => {
                if (day.koRecords.length == 0) {
                  //return( (<Button   onClick={() => console.log(`KO records on ${day.date}`,day.koRecords)} icon="semantic-checkmark" round small ariaLabel=" " secondary="true"/>))                     
                  return (<div id={index} key={index} className="serviceStatus" onClick={() => handleOpenModal(day.koRecords)}></div>)
                } else {
                  return (<div id={index} key={index} className="errorService" onClick={() => handleOpenModal(day.koRecords)} />)
                }
              }
              )}
            </div>
          </Layout.Item>
        )}
      </Layout>
      {selectedModal !== null && (
        <Modal
          buttonConfirmLabel={null}
          buttonConfirmText={null}
          buttonConfirmType={null}
          center
          id="modalId"
          onCancel={function noRefCheck() { }}
          onClickOutside={function noRefCheck() { }}
          onClose={handleCloseModal}
          shown
          title="Listado de KOs"
        >
          {selectedKO.length === 0 ? (<p>No hay registro de Kos para este día</p>) : (
            selectedKO.map((ko, index) => (
              <Layout.Item key={index}>
                <ul>{ticksToString(ko.ServiceInsertTime)}-{ko.ServiceStatus}</ul>
              </Layout.Item>
            )))}
        </Modal>
      )}
    </Fieldset>

  );
}


export default TimeLineGraph;