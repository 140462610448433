import AppService from "./AppService";

export async function SearchLog(body) {
 
  var queryString = process.env.REACT_APP_URL_SEARCH;

  var headers = {};
  headers[process.env.REACT_APP_AWS_APIKEY_NAME] =
    process.env.REACT_APP_AWS_APIKEY_VALUE;
  var config = {
    headers: headers,
  };

  try{
    var response = await AppService.post(queryString, body, config);
    if (response && response.data && response.status === 200) {        
      return JSON.stringify(response.data);        
    } else {
      console.error('Unespected response format',response);  
      throw new Error('Unespected response format');
    }    
  }catch(error){
    throw new Error(error.response ? error.response.data: error.message);
  }
}
