import { createContext, useState, useEffect } from "react";
import { Login } from "../services/LoginService";
import { AuthCookie } from "./../services/AuthCookie";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import LoadingContext from "./LoadingContextProvider";

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const {start, stop } = useContext(LoadingContext);
  
  useEffect(() => {
    let token = AuthCookie.get("token");
    if (token) {
      setUser(token);
    }
  }, []);

  const [user, setUser] = useState(() => {
    let token = AuthCookie.get("token");
    if (token) {
      return token;
    }
    return null;
  });

  useEffect(() => {  
    if (!user) {   
      setUser(null)           
      navigate("/");   
      AuthCookie.set(null);
    }

  }, [user, navigate]);

  const login = async (payload) => {
    start();
    let apiResponse = await Login(payload);
    if (apiResponse) {
      AuthCookie.set("token", apiResponse.AccessToken, {
        expires: moment(apiResponse.AccessTokenExpiration).toDate(),
      });

      setUser(apiResponse.AccessToken);
      navigate("/search");
    }
    stop();
  };
const logout = ()=>{
  setUser(null)
}
  return (
    <>
      <AuthContext.Provider value={{ user, login,logout }}>
        {children}
      </AuthContext.Provider>
    </>
  );
};

export default AuthContext;
