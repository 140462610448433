import React, {useRef, useEffect} from "react";
import { Grid } from "gridjs";
import PropTypes from "prop-types";
import moment from "moment";

const GridJs = ({headers, json}) => {
    const gridRef = useRef(null);
    const gridInstanceRef = useRef(null);

    const manageData = (list) => { 
        list.forEach( (x) => {
          x.RequestDate = moment(x.RequestDate).format("yyyy-MM-DD HH:mm:ss.SSSS");
          x.Date = moment(x.Date).format("yyyy-MM-DD HH:mm:ss.SSSS");
          x.Origin = managePath(x.Path, "origin");
          x.Destination = managePath(x.Path, "destination");
        });
        return list;
    };
    const managePath = (path, property) => {
        if(path === undefined || path === null) return "nulo";
        var json = JSON.parse(path);
        if(json[property] === undefined || json[property] === null || json[property] === "") return "vacío";
        return json[property];
    };

    const getHeadersPost = () =>{
        var headersPOST = {};
        headersPOST[process.env.REACT_APP_AWS_APIKEY_NAME] = process.env.REACT_APP_AWS_APIKEY_VALUE;
        headersPOST["Content-Type"] = "application/json";
        return headersPOST;
    };

    useEffect(() => { 
        //console.log(json);
        if(gridInstanceRef.current) gridInstanceRef.current.destroy();
        
        gridInstanceRef.current = new Grid({
            columns: headers,
            fixedHeader: true,
            resizable: true,
            sort: false,
            pagination: {
                limit: json?.Pagination,
                page: json?.Page,
                server: {
                    body: (prev, page, __) => {
                        var jsonx = JSON.parse(prev);
                        jsonx.Page = page;
                        return JSON.stringify(jsonx);
                    }
                }
            },
            server: {
                url: process.env.REACT_APP_URL_SEARCH,
                method: 'POST',
                headers:  getHeadersPost(),
                body: JSON.stringify(json),
                then: (data) =>{ 
                    manageData(data?.LogItems);
                    return data?.LogItems.map(x => headers.map(header => x[header.id]) ); //if(header.id==="MasDatos") return _(<button className={"py-2 px-4 border rounded-md text-white bg-blue-600"} onClick={() => handleOpenModal(x)}>Edit</button>);                    
                },
                handle: (res) => {
                    //console.log(res);
                    if(res.ok) return res.json();
                    return { LogItems : [] };
                },
                total: data => data?.Count
            },  
            language: {
                'search': {
                  'placeholder': '🔍 Buscar...'
                },
                'pagination': {
                  'previous': '<',
                  'next': '>',
                  'showing': '😃 Mostrando del registro',
                  'to': 'al',
                  'of': 'de',
                  'results': 'resultados'
                }
            }
        });
        
        gridInstanceRef.current.render(gridRef.current);
        return () => gridInstanceRef.current && gridInstanceRef.current.destroy();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [json]);
    return <div ref={gridRef}></div>;
};
GridJs.propTypes = {
    headers: PropTypes.array,
    json: PropTypes.object,
};
export default GridJs;