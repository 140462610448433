import axios from "axios";
import { config } from "./Config";

export const axiosClient = axios.create();

axiosClient.defaults.headers = config.headers;

const AppService = {
  
  get: async function Get(url, config) {
    
    try {
      const response = await axiosClient
        .get(url, config);
      return response;
    } catch (error) {
      return error;
    }
  },

  post: async function Post(url, data, config) {
   
    try {
      const response = await axiosClient
        .post(url, data, config);
      return response;
    } catch (error) {
      if (error?.response) return error.response;
      return error;
    }
  },
};
export default AppService;
