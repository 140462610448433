import { Outlet } from "react-router-dom";

import {
  Main,
  ContentSection,
  Wrapper,
  Spinner,
} from "@vwfs-bronson/bronson-react";
import "@vwfs-bronson/bronson-bluelabel/dist/css/style.min.css";
import { HeaderComponent } from "../components/Header";
import { useContext } from "react";
import LoadingContext from "../contexts/LoadingContextProvider";
import AuthContext  from "../contexts/AuthContextProvider";


function Initializer() {
  const { isLoading } = useContext(LoadingContext);
  const { user,logout } = useContext(AuthContext);
  

  return (
    <>
      <Spinner section busy={isLoading}>        
        <HeaderComponent prop={{user,logout}}></HeaderComponent>       
        <Main>
          <ContentSection pageWrap>
            <Wrapper>
              <Outlet />
            </Wrapper>
          </ContentSection>
        </Main>
      </Spinner>
    </>
  );
}

export default Initializer;
