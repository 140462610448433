import { createContext, useState } from "react";
import { useContext } from "react";
import LoadingContext from "./LoadingContextProvider";
import { getMonitorStatus } from "../services/MonitorService";

const MonitorContext = createContext();

export const MonitorContextProvider = ({ children }) => {
  const { start, stop } = useContext(LoadingContext);
  const [service, setService] = useState([]);

  const search = async (payload) => {
    start();
    let result = await getMonitorStatus(payload);
    if (result) {
      setService(JSON.parse(result));
    }
    stop();
  };

  return (
    <>
      <MonitorContext.Provider value={{ search, service }}>
        {children}
      </MonitorContext.Provider>
    </>
  );
};

export default MonitorContext;
