import React, { useState } from "react";
import {
  Button,
  ButtonContainer,
  Card,
  Fieldset,
  Form,
  FormField,
  FormHeading,
  Input,
  Layout,
} from "@vwfs-bronson/bronson-react";
import { useContext } from "react";
import { addErrorToInput } from "../utils";
import AuthContext from "./../contexts/AuthContextProvider";
export const AuthPage = () => {
  const { login } = useContext(AuthContext);

  const [userId, setUserId] = useState();
  const [password, setPassword] = useState();

  const doLogin = async (e) => {
    
    e.preventDefault();
    if (!userId) {
      addErrorToInput("user");
    }
    if (!password) {
      addErrorToInput("password");
    }
    if (userId && password) {
      let payload = {
        userId: userId,
        password: password,
      };
      login(payload);
    }
  };

  return (
    <React.Fragment key=".0">
      <Layout center>
        <Layout.Item default="1/3">
          <Card element="article">
            <Form floatingLabel default="1/3">
              <React.Fragment key=".0">
                <Fieldset>
                  <FormHeading>Login</FormHeading>
                  <Fieldset.Row>
                    <Layout>
                      <Layout.Item default="1/1">
                        <FormField
                          labelForId="input-1"
                          labelText="Usuario"
                          type="input"
                        >
                          <Input
                            id="user"
                            data-testid="user-input"
                            onChange={(e) => setUserId(e.target.value)}
                          />
                        </FormField>
                      </Layout.Item>
                      <Layout.Item default="1/1">
                        <FormField
                          labelForId="input-2"
                          labelText="Password"
                          type="input"
                        >
                          <Input
                            id="password"
                            data-testid="password-input"
                            type="password"
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </FormField>
                      </Layout.Item>
                    </Layout>
                  </Fieldset.Row>
                </Fieldset>
                <Fieldset>
                  <Fieldset.Row>
                    <ButtonContainer nav>
                      <Button id="btnLogin" onClick={doLogin}>
                        Entrar
                      </Button>
                    </ButtonContainer>
                  </Fieldset.Row>
                </Fieldset>
              </React.Fragment>
            </Form>
          </Card>
        </Layout.Item>
      </Layout>
      <Layout center>{process.env.REACT_APP_VERSION}</Layout>
    </React.Fragment>
  );
};
