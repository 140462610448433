import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  AreaChart,
  Area
} from "recharts";
import { Layout, Fieldset } from "@vwfs-bronson/bronson-react";
import React, { useEffect, useState } from "react";
import { ticksToString } from "../utils.js";

function Graph({ nameProp, dataProp }) {
  const [name, setName] = useState("");
  const [data, setData] = useState([]);

  useEffect(() => {
    //Ordeno por fecha
    dataProp.sort((a, b) => a.ServiceInsertTime - b.ServiceInsertTime);

    dataProp.map(
      (dataProp) =>
        (dataProp.ServiceInsertTime = ticksToString(dataProp.ServiceInsertTime))
    );

    dataProp.map(
      (dataProp) => {
        if (dataProp.ServiceStatus === "OK"){
          dataProp[200] = dataProp.ResponseTime 
        }
        else{
          dataProp[400] = dataProp.ResponseTime 
        }
      }
    );
    setData(dataProp);
    setName(nameProp);
  }, []);

  return (
    <Fieldset>
      <Layout center>
        {data.length !== 0 && (
          <Layout.Item default="1/3" s="1/1">
            <p>Servicio {name}</p>
            <AreaChart width={730} height={250} data={data}
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
              <defs>
                <linearGradient id="colorUv">
                  <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                  <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                </linearGradient>
                <linearGradient id="colorPv">
                  <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                  <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                </linearGradient>
              </defs>
              <XAxis dataKey="ServiceInsertTime" />
              <YAxis datakey="ResponseTime"/>
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              <Area type="monotone" dataKey='200' stroke="#82ca9d" fillOpacity={1} fill="url(#colorUv)" />
              <Area type="monotone" dataKey='400' stroke="#8884d8" fillOpacity={1} fill="url(#colorPv)" />
            </AreaChart>
          </Layout.Item>
        )}
      </Layout>
    </Fieldset>
  );
}

export default Graph;
