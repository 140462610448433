import { SiteNav} from "@vwfs-bronson/bronson-react";
import { useNavigate } from "react-router-dom";

export const NavBarComponent=()=>{
    const navigate = useNavigate();
    return(
    <>
  <SiteNav>
    <SiteNav.Area>
      <SiteNav.Item        
        active
        label="Logs"
        onClick={function (){navigate("/search")}}
      ></SiteNav.Item>
       <SiteNav.Item     
        active
        label="Monitor"
        onClick={function (){navigate("/monitor")}}
      ></SiteNav.Item>
      </SiteNav.Area>
      </SiteNav></>)
}