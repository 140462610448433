import React, { useState, useEffect } from "react";
import moment from "moment";
import { Layout,  Button,  ButtonContainer,  Card,  Form,  FormHeading,  Fieldset, ExpandableContent, FloatingButton } from "@vwfs-bronson/bronson-react";
import { FormFieldVWFS } from "../components/FormFieldVWFS";
import { useForm } from "../hooks/useForm";
import { SearchLog } from "../services/SearchService";
import ModalTabs from "../components/ModalTabs";
import { copyToClipboard } from "../utils";
import {ExportCSV} from "../components/ExportCSV";
import { h } from "gridjs";
import { _ } from "gridjs-react";
import GridJs from "../components/GridJs";
import "gridjs/dist/theme/mermaid.css";
import "../css/styles.css";

export const SearchPage = () => {
  const [endDate, setEndDate] = useState();//
  const [defaultSelected] = useState("25");
  const [defaultCatalog] = useState("GENERICA");
  const [startDate, setStartDate] = useState();
  const [data, setData] = useState({ 
    StartDate: moment(startDate).format(),
    EndDate: moment(endDate).format(),
    Pagination: parseInt(defaultSelected),
    Table: defaultCatalog, 
  });
  const [paramsModal, setParamsModal] = useState({
    Id: "modalRequestResponse",
    Title: "Más Datos",
    ConfirmType: "submit",
    defaultTab: "request",
    Tab: "request",
  });
  const [headers, setHeaders] = useState();
  const [dataCSV, setDataCSV] = useState(null);
  const [canSearch, setCanSearch] = useState(false);
  const [selectedModal, setSelectedModal] = useState(null);
  const [isGridActive, activeGrid] = useState(false);
  const [isDPActive, activeDP] = useState(true);
  const [filters, handleOnChange, reset] = useForm({
    Usuario: "",
    application: "",
    service: "",
    method: "",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    maxregisters: defaultSelected,
    catalogs: defaultCatalog,
  });

  const headers1 = [
    { name: "Fecha Inicio", id: "RequestDate", width: "226px", sort: false }, //0    
    { name: "Servicio", id: "Service" },                                      //1
    { name: "Método", id: "Method" },                                         //2
    { name: "Acción", id: "Action", hidden: false },                          //3
    { name: "Usuario", id: "User", hidden: false },                           //4
    { name: "Aplicación", id: "Application", hidden: false },                 //5
    { name: "Fecha Fin", id: "Date", width: "226px", hidden: false },         //6
    { name: "Origen", id: "Origin", hidden: true },                           //7
    { name: "Destino", id: "Destination", hidden: true },                     //8   
    { name: "Petición", id: "Request", hidden: true },                        //9
    { name: "Respuesta", id: "Response", hidden: true },                      //10
    {
      name: "Más Datos",
      id: "MasDatos",
      formatter: (__, row) =>{
        return _(<Button onClick={() => handleOpenModal(row)} icon="semantic-info" round ariaLabel=" "/>);   
      },
      width: "122px",
      sort: false
    },    //11
  ];

  const headers2 =  [
    { name: "Fecha Inicio", id: "RequestDate", width: "226px", sort: false },    
    { name: "Servicio", id: "Service" },
    { name: "Método", id: "Method" },
    { name: "Acción", id: "Action", hidden: true },
    { name: "Usuario", id: "User", hidden: true },
    { name: "Aplicación", id: "Application", hidden: true },
    { name: "Fecha Fin", id: "Date", width: "226px", hidden: true },
    { name: "Origen", id: "Origin", hidden: false },    
    { name: "Destino", id: "Destination", hidden: false },    
    { name: "Petición", id: "Request", hidden: true },    
    { name: "Respuesta", id: "Response", hidden: true },
    {
      name: "Más Datos",
      id: "MasDatos",
      formatter: (cell, row) => {
        return h('button',{className:'c-btn c-btn--icon c-btn--icon-only c-btn--round', onClick: () => handleOpenModal(row)}, [h('span',{className:'c-btn__text'}, [h('i',{className:'c-icon c-icon--[semantic-info] c-icon--xsmall'},'')] )] );
      },      
      width: "122px",
      sort: false
    },    
  ];
  
  const dateDefault = moment('1987-11-18T00:00:00+01:00','YYYY-MM-DD[T]HH:mm:ssZ');

  const confDatePicker = {
    disableDays: [
      {
        from: moment(new Date()).toDate(),
        to: moment(new Date()).add(100000, "Y").toDate(),
      },
    ],
    dateFormat: "d-m-Y",
    locale: "es",
  };

  const confTimePicker = {
    dateFormat: "H:i",
    locale: "es",
  };

  const listItems = [
    { key: 1, Value: "25", Text: "25" },
    { key: 2, Value: "50", Text: "50" },
    { key: 3, Value: "75", Text: "75" },
    { key: 4, Value: "100", Text: "100" },
    { key: 5, Value: "150", Text: "150" },
    { key: 6, Value: "175", Text: "175" },
    { key: 7, Value: "200", Text: "200" },
  ];

  const listCatalogs = [
    { key: 1, Value: "GENERICA", Text: "General" },
    { key: 2, Value: "WEBCALC", Text: "WebCalc" },
    { key: 3, Value: "CUMBRE", Text: "Cumbre" }
  ];

  const tabs = [
    {
      Id: "request",
      Value: selectedModal?.Request,
      HeaderName: "Request",
      ButtonText: "Copy Clipboard",
      ButtonAction: () => copyToClipboard(selectedModal?.Request),
    },
    {
      Id: "response",
      Value: selectedModal?.Response,
      HeaderName: "Response",
      ButtonText: "Copy Clipboard",
      ButtonAction: () => copyToClipboard(selectedModal?.Response),
    },
    {
      Id: "origin",
      Value: selectedModal?.Origin,
      HeaderName: "Origin",
      ButtonText: "Copy Clipboard",
      ButtonAction: () => copyToClipboard(selectedModal?.Origin),
    },
    {
      Id: "destination",
      Value: selectedModal?.Destination,
      HeaderName: "Destination",
      ButtonText: "Copy Clipboard",
      ButtonAction: () => copyToClipboard(selectedModal?.Destination),
    }
  ];

  function handleOpenModal(row) {
    const o ={
      Request: row.cells[9].data,
      Response: row.cells[10].data,
      Origin: row.cells[7].data,
      Destination: row.cells[8].data,
    };
    setSelectedModal(o);
  }

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    filters.Page = 0;
    setCanSearch(true);
  };

  const fetchdata= async () => {
    try {
      setData({
        User: filters.user,
        Service: filters.service,
        Method: filters.method,
        Application: filters.application,
        StartDate: moment(startDate).format().replace("+02","+00").replace("+01","+00"),
        EndDate: moment(endDate).format().replace("+02","+00").replace("+01","+00"),
        Pagination: parseInt(filters.maxregisters),
        Page: parseInt(filters?.Page??0),
        Table: filters.catalogs, 
      });      
    } catch (error) {
      console.error(error);
    } finally {
      setCanSearch(false);
    }
  };

  //Sin paginacion
  const fetchdataCSV=async()=>{
    try 
    {
      var json = {
        User: filters.user,
        Service: filters.service,
        Method: filters.method,
        Application: filters.application,
        StartDate: moment(startDate).format().replace("+02","+00").replace("+01","+00"),
        EndDate: moment(endDate).format().replace("+02","+00").replace("+01","+00"),
        Table: filters.catalogs,            
      };
      var dataCSV = await SearchLog(json);
      if (dataCSV === undefined) 
      {
        setDataCSV({});
        return dataCSV;
      }
      var jsonData = JSON.parse(dataCSV);
      jsonData.LogItems = manageData(jsonData.LogItems);
      setDataCSV(jsonData);     
      return JSON.stringify(jsonData);
    } catch (error) 
    {
        console.error(error);
    } finally {
        setCanSearch(false);
    }
  }

  useEffect(
    () => {
      if (canSearch) {
        fetchdata();
        activeGrid(true);
        document.getElementById("floatingButton").querySelector("button").removeAttribute('disabled');
        document.getElementById("expandableContent").querySelector(".o-page-wrap").querySelector("button").removeAttribute('disabled');
        document.getElementById("expandableContent").querySelector(".o-page-wrap").querySelector("button").click();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canSearch]);

  useEffect(() => {    
    setHeaders(headers1);
    setStartDate(dateDefault);
    setEndDate(dateDefault);
    document.getElementById("expandableContent").querySelector(".o-page-wrap").querySelector("button").setAttribute('disabled',null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);    

  const manageData = (list) => { 
    list.forEach( (x) => {
      x.RequestDate = moment(x.RequestDate).format("yyyy-MM-DD HH:mm:ss.SSSS");
      x.Date = moment(x.Date).format("yyyy-MM-DD HH:mm:ss.SSSS");
      x.Origin = managePath(x.Path, "origin");
      x.Destination = managePath(x.Path, "destination");
    });
    return list;
  };

  const managePath = (path, property) => {
    if(path === undefined || path === null) return "nulo";
    var json = JSON.parse(path);
    if(json[property] === undefined || json[property] === null || json[property] === "") return "vacío";
    return json[property];
  };

  const hardReset = () => {
    activeDP(false);
    reset({
      Usuario: "",
      application: "",
      service: "",
      method: "",
      startDate: "",
      endDate: "",
      startTime: "",
      endTime: "",
      maxregisters: defaultSelected,
      catalogs: defaultCatalog,
    });
    setEndDate(dateDefault);
    setStartDate(dateDefault);

    setData({ 
      StartDate: moment(startDate).format(),
      EndDate: moment(endDate).format(),
      Pagination: parseInt(defaultSelected),
      Table: defaultCatalog, 
    });
    activeGrid(false);
    document.getElementById("floatingButton").querySelector("button").setAttribute('disabled',null);
    document.getElementById("expandableContent").querySelector(".o-page-wrap").querySelector("button").setAttribute('disabled',null);
    setTimeout(() => activeDP(true),1);
  };

  const floatingClick = () => {
    let res = headers.find((x) => x.id === "Action");
    if(res.hidden){
      setHeaders( headers1);
    }else{
      setHeaders(headers2);
    }
    filters.Page = document.querySelector(".gridjs-currentPage").innerHTML - 1;
    fetchdata();
  };

  const timePickerChangeStart = (e) => {
    var date = moment(startDate);
    var time = e.split(':');
    date.hour(time[0]);
    date.minute(time[1]);
    setStartDate(date);
  };

  const timePickerChangeEnd = (e) => {
    var date = moment(endDate);
    var time = e.split(':');
    date.hour(time[0]);
    date.minute(time[1]);
    setEndDate(date);
  };

  const patchTabs = (id) =>{
    setParamsModal({
      Id: "modalRequestResponse",
      Title: "Más Datos",
      ConfirmType: "submit",
      defaultTab: "request",
      Tab: id,
    });
    window.dispatchEvent(new Event('resize'));
  };

  return (
    <>
      <Layout center>
      <Layout.Item default="1/1" className="lay-form">      
          <ExpandableContent
            triggerLabel="Búsqueda de logs de aplicaciones"
            triggerLabelClose="Búsqueda de logs de aplicaciones"
            id = "expandableContent"
            defaultOpen
            noBackground
          >                    
            <Card element="article">
              <Form onSubmit={handleOnSubmit} autoComplete="off">
                <Fieldset>
                  <FormHeading>
                    Búsqueda de logs de aplicaciones
                  </FormHeading>
                  <Fieldset.Row>
                    <Layout>
                      <FormFieldVWFS
                        size="1/3"
                        xs="1/1"
                        id="service"
                        label="Servicio"
                        type="input"
                        onchange={handleOnChange}
                      />
                      <FormFieldVWFS
                        size="1/3"
                        xs="1/1"
                        id="method"
                        label="Método"
                        type="input"
                        onchange={handleOnChange}
                      />                      
                      {isDPActive &&(
                      <FormFieldVWFS
                        size="1/6"
                        xs="1/2"
                        id="startDate"
                        label="Fecha desde"
                        type="datepicker" 
                        onchange={(e) => {
                          var date = moment(startDate);
                          var time = e.split('-');
                          date.date(time[0]);
                          date.month(time[1]-1);
                          date.year(time[2]);
                          setStartDate(date);
                        }}
                        confDatePicker={confDatePicker}
                        autoComplete={"off"}
                      />
                      )}   {isDPActive &&(        
                      <FormFieldVWFS 
                          size="1/6"
                          xs="1/2"
                          id="startTime"
                          label="Hora desde"
                          type="timepicker"
                          onblur={timePickerChangeStart}
                          onchange={timePickerChangeStart}
                          confDatePicker={confTimePicker}
                          autoComplete={"off"}
                          time24hr
                        />  )}  
                    </Layout>
                  </Fieldset.Row>
                  <Fieldset.Row>
                    <Layout>
                    <FormFieldVWFS
                        size="1/3"
                        xs="1/1"
                        id="user"
                        label="Usuario"
                        type="input"
                        onchange={handleOnChange}
                      />
                      <FormFieldVWFS
                        size="1/3"
                        xs="1/1"
                        id="application"
                        label="Aplicación"
                        type="input"
                        onchange={handleOnChange}
                      />
                      {isDPActive &&(
                        <FormFieldVWFS 
                          size="1/6"
                          xs="1/2"
                          id="endDate"
                          label="Fecha hasta"
                          type="datepicker"
                          onchange={(e) => {
                            var date = moment(endDate);
                            var time = e.split('-');
                            date.date(time[0]);
                            date.month(time[1]-1);
                            date.year(time[2]);
                            setEndDate(date);//.add(1, "d")
                          }}
                          confDatePicker={confDatePicker}
                          autoComplete={"off"}
                        />
                      )}       {isDPActive &&(         
                      <FormFieldVWFS 
                          size="1/6"
                          xs="1/2"
                          id="endTime"
                          label="Hora hasta"
                          type="timepicker"
                          onblur={timePickerChangeEnd}
                          onchange={timePickerChangeEnd}
                          confDatePicker={confTimePicker}
                          autoComplete={"off"}
                        />           )}   
                    </Layout>
                  </Fieldset.Row>
                  <Fieldset.Row>
                    <Layout center>
                      <FormFieldVWFS
                        size="1/3"
                        xs="1/2"
                        id="maxregisters"
                        label="Nº Resultados"
                        type="select"
                        onchange={handleOnChange}
                        items={listItems}
                        placeholder="Numero registros"
                        defaultItem={defaultSelected}
                      />
                      <FormFieldVWFS
                        size="1/3"
                        xs="1/2"
                        id="catalogs"
                        label="Catálogos"
                        type="select"
                        onchange={handleOnChange}
                        items={listCatalogs}
                        placeholder="Selección de catálogo"
                        defaultItem={defaultCatalog}
                      />
                    </Layout>
                  </Fieldset.Row>
                  <Fieldset>
                    <Fieldset.Row>
                      <ButtonContainer center>
                        <Button type="submit">Buscar</Button>
                        <Button type="reset" secondary onClick={() => hardReset()}  >
                          Limpiar
                        </Button>
                        <ExportCSV  
                          csvData={dataCSV}                                                             
                          fileName="LogsReport"   
                          fetchdataCSV={fetchdataCSV}
                          >             
                        </ExportCSV>
                      </ButtonContainer>
                    </Fieldset.Row>
                  </Fieldset>
                </Fieldset>
              </Form>
            </Card>
          </ExpandableContent>
        </Layout.Item>
        <Layout.Item className="lay-table">
        {isGridActive &&(
          <GridJs 
            headers={headers} 
            json =  {data}
          />
        )}
        </Layout.Item>          
      </Layout>
      <FloatingButton
        controls="floatingButton"
        icon="semantic-reload"
        label="Cambia tabla"
        id="floatingButton"
        disabled
        onClick = {() => floatingClick()}
      />
      {selectedModal !== null && (
        <ModalTabs
          params={paramsModal}
          tabs={tabs}
          patchTabs={patchTabs}
          handleCloseModal={() => {
            setSelectedModal(null);
          }}
        />
      )}
    </>
  );
};