import React from "react";
import { DatePicker, TimePicker, Input, Layout, Select } from "@vwfs-bronson/bronson-react";

export const FormFieldVWFS = ({ size, xs, id, 
    label, type, confDatePicker, onchange, items, placeholder, defaultItem, autoComplete, onblur }) => {        
        let voidInSelect;
        if (id === "maxregisters" ){
            voidInSelect = <Select.Item key="0" value="10000000" children="Sin paginación" />;
        }
    return (
        <>
            <Layout.Item default={size} l={xs}>
                <label>{label}</label>
                {/* <FormField labelForId={id} labelText={label} type={type === "datepicker" ? "other": type}> */}
                    {
                        (type === "datepicker") ?
                            <DatePicker
                                onChange={onchange}
                                locale={confDatePicker.locale}
                                disableDays={confDatePicker.disableDays}
                                id={id}
                                dateFormat={confDatePicker.dateFormat}
                                name={id}
                                autoComplete={autoComplete} 
                            />
                        :
                        (type === "timepicker") ?
                            <TimePicker
                                onChange={onchange}
                                onBlur={onblur}
                                locale={confDatePicker.locale}
                                id={id}
                                name={id}
                                autoComplete={autoComplete} 
                                time24hr
                            />
                        :
                        (type === "select") ?
                            <Select placeholder={placeholder} name={id} id={id} onChange={onchange} defaultValue={defaultItem}>
                                {voidInSelect}
                                {
                                    items.length && items.map(res => (
                                        <Select.Item key={res.key} value={res.Value} children={res.Text} />
                                    ))
                                }
                            </Select>
                        :
                            <Input id={label} onChange={onchange} name={id} autoComplete={autoComplete} />
                    }
                    
                {/* </FormField> */}
            </Layout.Item>
        </>
    )

};